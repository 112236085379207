import React, { Component } from 'react';
import GenericPage from '../components/genericpage';
import TotmSchoolData from '../data/totmschool.json'


const TOTMSchool = () => {
  return (
    <GenericPage PageData = {TotmSchoolData} />
  )
}

export default TOTMSchool